import React, { FC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import SearchContainer from './components/SearchContainer'
import MobileSearchContainer from './components/MobileSearchContainer'
import { NavigationItem } from '@/utils/navigation/Standard/types/NavigationItem'

export type SearchBarProps = {
    placeholder: string
    searchUrl: string
    popularUrl: string
}

type Props = SearchBarProps & {
    navigationItems?: NavigationItem[]
}

const Search: FC<Props> = ({ placeholder, searchUrl, popularUrl }) => {
    return (
        <>
            <NonFlexingContainer display={['flex', 'flex', 'flex', 'none']}>
                <MobileSearchContainer
                    placeholder={placeholder}
                    searchUrl={searchUrl}
                    popularUrl={popularUrl}
                />
            </NonFlexingContainer>
            <NonFlexingContainer display={['none', 'none', 'none', 'flex']}>
                <SearchContainer
                    placeholder={placeholder}
                    searchUrl={searchUrl}
                    popularUrl={popularUrl}
                />
            </NonFlexingContainer>
        </>
    )
}

export default Search
