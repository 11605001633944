import * as Sentry from '@sentry/nextjs'

export enum LogType {
    network = 'NETWORK',
    silentException = 'SILENT_EXCEPTION',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debug(...objects: any[]): void {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(objects)
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(message?: string, cause?: Error, extra?: Record<string, any>): void {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.debug(message || cause?.message, extra, cause)
    }

    const error = message ? new Error(message, { cause }) : cause

    if (error) {
        Sentry.withScope(scope => {
            scope.setLevel('log')
            Sentry.captureException(error, { extra })
        })
    }
}
