import { TeamClientModel } from '@sport1/types/sportData'
import { slugify } from '@/helpers/slugHelper'
import canOpenTeam from '@/utils/sportsData/canOpenTeam'

const getTeamBaseHref = (teamId: string, teamName: string): string => {
    return `/team/${slugify(teamName)}/${teamId}`
}

export const createTeamHref = (team?: TeamClientModel): string | undefined => {
    if (team?.name && team?.id && canOpenTeam(team)) {
        return getTeamBaseHref(team.id, team.name)
    }
}

export const createTeamWidgetHref = (
    team: TeamClientModel,
    widget: 'highlights'
): string | undefined => {
    if (team.name && team.id) {
        return `${getTeamBaseHref(team.id, team.name)}/${widget}`
    }
}
