import { useEffect, useState } from 'react'
import { MAX_CONTENT_WIDTH } from './constants'

const useSpaceAfterContent = (maxWidth: number): Record<string, number> => {
    const [width, setWidth] = useState(0)
    const spaceFromContentToEdge = (width - MAX_CONTENT_WIDTH) / 2

    const handleResize = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return {
        spaceFromContentToEdge:
            spaceFromContentToEdge >= maxWidth ? maxWidth : spaceFromContentToEdge,
    }
}

export default useSpaceAfterContent
