import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ReactElement } from 'react'
import { DisplayProp } from '@sport1/news-styleguide/Types'
import DisplayAd from '@/components/Ads/AdSlot/DisplayAd'
import { AdPlatformPlacement } from '@/context/AdPlacementContext/AdPlacementProvider.types'

const getAdWithPlaceholder = (
    display: DisplayProp['display'],
    placement?: string,
    placementId?: string,
    testID?: string
): ReactElement => (
    <NonFlexingContainer {...{ display, testID }}>
        <DisplayAd disableBottomSpace {...{ placement, placementId }} />
    </NonFlexingContainer>
)

const renderAdWithPlaceholder = (ad: AdPlatformPlacement, testID?: string): ReactElement => {
    // Avoid rendering both two containers if the AdSlot id is the same
    if (ad.desktop?.placement === ad.mobile?.placement) {
        return getAdWithPlaceholder('flex', ad.desktop?.placement, ad.desktop?.placementId, testID)
    }
    return (
        <>
            {getAdWithPlaceholder(
                ['flex', 'flex', 'none'],
                ad.mobile?.placement,
                ad.mobile?.placementId,
                testID
            )}
            {getAdWithPlaceholder(
                ['none', 'none', 'flex'],
                ad.desktop?.placement,
                ad.desktop?.placementId,
                testID
            )}
        </>
    )
}

export default renderAdWithPlaceholder
