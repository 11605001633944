import React, { FC, useMemo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { useRouter } from 'next/router'
import BreadCrumbsData from './base'
import { useNavigation } from '@/utils/navigation/Standard'
import { GLOBAL_LAYOUT_COMPONENT_SPACE } from '@/utils/content/constants'

export type BreadCrumbProps = {
    title: string
    link?: string
    attachment?: string
}

const sportwettenChildTags = ['602', '621', '622', '623']

const BreadCrumbs: FC = () => {
    const { navigation } = useNavigation()
    // useRouter and mock from breadcrumb tests should be removed after we will get rid of event teaser
    const { asPath } = useRouter()
    const breadCrumbs = useMemo(() => {
        const desktop: BreadCrumbProps[] = []
        let mobile: BreadCrumbProps[] = []
        desktop.push({ title: 'Home', link: '/' })
        mobile.push({ title: 'Home', link: '/' })
        if (navigation?.tag?.parent) {
            desktop.push({
                title: navigation?.tag?.parent.title,
                link: navigation?.tag?.parent.href,
            })
            if (!navigation?.breadCrumbs?.pageTitle) {
                mobile.push({
                    title: navigation?.tag?.parent.title,
                    link: navigation?.tag?.parent.href,
                })
            }
        }

        if (navigation?.tag) {
            const tagBreadcrumb = {
                title: navigation?.tag.title,
                link: navigation?.tag.href,
                attachment: sportwettenChildTags.includes(navigation.tag.contextId)
                    ? ' | Anzeigen'
                    : undefined,
            }
            desktop.push(tagBreadcrumb)
            mobile.push(tagBreadcrumb)
        }

        if (navigation?.breadCrumbs) {
            if (navigation.breadCrumbs.additionalBreadCrumbs?.length) {
                const additionalBreadCrumbs = navigation.breadCrumbs.additionalBreadCrumbs
                desktop.push(...additionalBreadCrumbs)
                mobile.push(...additionalBreadCrumbs)
            }

            if (navigation.breadCrumbs.pageTitle) {
                const articleBreadCrumb = {
                    title: navigation.breadCrumbs.pageTitle,
                }
                desktop.push(articleBreadCrumb)
                mobile.push(articleBreadCrumb)
            }
        }

        mobile = mobile.slice(-2)
        return {
            desktop,
            mobile,
        }
    }, [navigation])
    return (
        <NonFlexingContainer
            paddingX={GLOBAL_LAYOUT_COMPONENT_SPACE}
            paddingTop="spacing-4"
            // paddingBottom should be removed after we will get rid of event teaser
            paddingBottom={
                asPath === '/channel/fussball/em'
                    ? ['spacing-4', 'spacing-4', 'spacing-none']
                    : 'spacing-none'
            }
        >
            <NonFlexingContainer centerVertical horizontal display={['none', 'none', 'flex']}>
                <BreadCrumbsData breadCrumbs={breadCrumbs.desktop} />
            </NonFlexingContainer>
            <NonFlexingContainer centerVertical horizontal display={['flex', 'flex', 'none']}>
                <BreadCrumbsData breadCrumbs={breadCrumbs.mobile} />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default BreadCrumbs
