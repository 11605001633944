import React, { FC, memo, useMemo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Fallback from '@/components/Fallback'

type Props = {
    pageComponents?: JSX.Element[]
}

const PageContentLayoutFallback: FC<Props> = ({ pageComponents = [] }) => {
    const ads = useMemo(
        () => pageComponents.filter(c => c.props && c.props?.type && c.props?.type === 'AD'),
        [pageComponents]
    )

    return (
        <>
            {ads?.[0]}
            <NonFlexingContainer
                marginBottom={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-9']}
                testID="fallback-text-wrapper"
            >
                <Fallback
                    testID="fallback-text"
                    text="Aktuell sind keine Inhalte verfügbar. Versuche es zu einem späteren Zeitpunkt erneut."
                    padding={['spacing-7', 'spacing-7', 'spacing-9', 'spacing-10']}
                />
            </NonFlexingContainer>
            {ads?.[1]}
        </>
    )
}

export default memo(PageContentLayoutFallback)
