import { RefObject, useEffect, useRef, useState } from 'react'

const useInViewport = (
    componentRef: RefObject<Element | null>,
    isInitiallyInViewPort = false,
    options?: IntersectionObserverInit
): boolean => {
    const [isInViewport, setIsInViewport] = useState(isInitiallyInViewPort)
    const observer = useRef<IntersectionObserver | undefined>()

    useEffect(() => {
        if (!window.IntersectionObserver) {
            return
        }
        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new window.IntersectionObserver(
            ([entry]) => setIsInViewport(entry.isIntersecting),
            options
        )

        const { current: currentObserver } = observer

        if (componentRef.current) {
            currentObserver.observe(componentRef.current)
        }

        return () => {
            currentObserver.disconnect()
        }
    }, [componentRef, options])

    return isInViewport
}

export default useInViewport
