export default function isSport1(url?: string): boolean {
    if (!url) {
        return false
    }

    if (url.startsWith('http') || url.startsWith('//')) {
        return url.includes('sport1.de') || url.includes('sport1.it')
    }

    return true
}
