import dayjs, { ManipulateType } from 'dayjs'

// if there is not a expireDate set then the cookie is setting to 5 min
export const writeCookie = ({
    name,
    value,
    expireDate,
}: {
    name: string
    value: string
    expireDate?: {
        value: number
        unit: ManipulateType
    }
}) => {
    if (typeof window !== 'undefined') {
        const setDate = (
            expireDate ? dayjs().add(expireDate.value, expireDate.unit) : dayjs().add(5, 'minutes')
        )
            .toDate()
            .toUTCString()

        document.cookie = `${name}=${value}; expires=${setDate}; path=/`
    }
}

const readCookieToObject = () => {
    const cookieData: Record<string, string> = {}
    document.cookie.split('; ').forEach(el => {
        const [key, value] = el.split('=')
        cookieData[key] = value
    })
    return cookieData
}

export const readCookie = (name: string) => {
    if (typeof window !== 'undefined') {
        const cookieData = readCookieToObject()
        return cookieData[name]
    }
}

export const readCookies = (nameList: Array<string>) => {
    if (typeof window !== 'undefined') {
        const cookieData = readCookieToObject()
        const returnValue: { [p: string]: string } = {}
        return nameList.reduce((obj, key) => {
            return {
                ...obj,
                [key]: cookieData[key],
            }
        }, returnValue)
    }
}

export const clearCookie = (name: string) => {
    if (typeof window !== 'undefined') {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
    }
}

export const clearAllCookies = () => {
    if (typeof window !== 'undefined') {
        document.cookie.split('; ').forEach(el => {
            const [key] = el.split('=')
            document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
            document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
            document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=${location.hostname}`
            document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=${location.hostname
                ?.split('.')
                .slice(-2)
                .join('.')}`
        })
    }
}
