import { LabelProps, TagProps } from '@sport1/types/web'
import {
    CompetitionClientModel,
    MatchClientModel,
    PlayerClientModel,
    SportClientModel,
} from '@sport1/types/sportData'
import { createTeamHref, createTeamWidgetHref } from './team'
import { slugify } from '@/helpers/slugHelper'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

type SoccerLivetickerPage =
    | 'uebersicht'
    | 'ticker'
    | 'tabelle'
    | 'statistik'
    | 'aufstellung'
    | 'matchday'

type WintersportLivetickerPage = 'liveticker' | 'ergebnisse'

export type LivetickerPage = SoccerLivetickerPage | WintersportLivetickerPage

type CreateMatchLivetickerHrefProps = {
    tag?: TagProps | LabelProps
    matchId?: string
    page?: LivetickerPage
}
type GetMatchLivetickerHrefProps = Omit<CreateMatchLivetickerHrefProps, 'matchId'> & {
    match: MatchClientModel
}
type CreateCompetitionLivetickerHrefProps = {
    tag?: TagProps | LabelProps
    competitionId?: string
    sportId?: string
    matchId?: string
}
type CreateStandingsHrefProps = {
    tag?: TagProps | LabelProps
    competitionId?: string
}
type CreateGameplanHrefProps = {
    tag?: TagProps | LabelProps
    competitionId?: string
}
type CreateCleansheetsHrefProps = {
    tag?: TagProps | LabelProps
}
type CreateCalendarHrefProps = {
    tag?: TagProps | LabelProps
    sportId?: string
}
type CreateTourDeFranceResultsHref = {
    competitionName?: string
    sportGroupName?: string
}
type CreateNewsHrefProps = {
    currentPath?: string
}
type RedirectHrefProps = {
    widget:
        | 'gameplan'
        | 'liveticker-overview'
        | 'liveticker-ticker'
        | 'standings'
        | 'cleansheets'
        | 'calendar'
    competition?: CompetitionClientModel
    match?: MatchClientModel
    sport?: SportClientModel
    sportId?: string
}
type CreateHighlightHrefProps = {
    tag?: TagProps | LabelProps
}

const getPlayerName = ({
    firstName,
    lastName,
    nickName,
}: PlayerClientModel): string | undefined => {
    if (firstName && lastName) {
        return `${firstName}-${lastName}`
    } else if (nickName) {
        return nickName
    }
    return firstName || lastName
}

export const getTeamPageHref = (tag: TagProps | LabelProps): string => {
    return `/team/${slugify(tag.seoTitle || tag.title)}/${tag.contextId}`
}

export const getPersonPageHref = ({
    seoTitle,
    firstName,
    lastName,
    nickName,
    id,
}: Pick<PlayerClientModel, 'firstName' | 'lastName' | 'nickName' | 'id'> & {
    seoTitle?: string
}): string | undefined => {
    if (!id) {
        return undefined
    }

    if (seoTitle) {
        return `/person/${slugify(seoTitle)}/${id}`
    }
    const playerName = firstName && lastName ? `${firstName} ${lastName}` : nickName || lastName
    return playerName ? `/person/${slugify(playerName)}/${id}` : undefined
}

export const getAuthorPageHref = (tag: TagProps | LabelProps, id: string): string => {
    const authorName = tag.seoTitle || tag.title
    return `/autoren/${slugify(authorName)}-${id}`
}

export const createMatchLivetickerHref = ({
    tag,
    matchId,
    page,
}: CreateMatchLivetickerHrefProps): string => {
    return matchId && tag?.slug
        ? `/daten${tag.slug}/live-ticker/${matchId}${page ? `/${page}` : ''}`
        : ''
}

export const getMatchLivetickerHref = ({
    match,
    tag,
    page: initialPage,
}: GetMatchLivetickerHrefProps): string | undefined => {
    if (match.hasTicker || match.competition?.hasTicker) {
        let page: LivetickerPage | undefined = initialPage
        if (!page && match.sport?.sportGroup?.sportIdentifier === sportIdentifiers.soccer.name) {
            page = match.isLive ? 'ticker' : 'uebersicht'
        }
        return createMatchLivetickerHref({
            tag,
            matchId: match.id,
            page,
        })
    }
}

export const createCompetitionLivetickerHref = ({
    tag,
    competitionId,
    sportId,
    matchId,
}: CreateCompetitionLivetickerHrefProps): string => {
    let slug = ''
    if (sportId) {
        slug = slug.concat(`/sportId/${sportId}`)
    }
    if (matchId) {
        slug = slug.concat(`/matchId/${matchId}`)
    }
    if (competitionId && tag?.slug) {
        return `/daten${tag.slug}/live-ticker/competition/${competitionId}${slug}`
    }
    return ''
}

export const createStandingsHref = ({ tag, competitionId }: CreateStandingsHrefProps): string => {
    if (tag?.slug) {
        const hasSubChannel = new RegExp('/.*/').test(tag.slug)
        if (!hasSubChannel && competitionId) {
            return `/daten${tag.slug}/tabelle/${competitionId}`
        }
        return `/daten${tag.slug}/tabelle`
    }
    return ''
}

export const createPlayerInfoHref = ({ id, ...player }: PlayerClientModel): string => {
    const playerName = getPlayerName(player)
    if (id && playerName) {
        return `/person/${slugify(playerName)}/${id}`
    }
    return ''
}

export const createGameplanHref = ({ tag, competitionId }: CreateGameplanHrefProps): string => {
    if (tag?.slug) {
        const hasSubChannel = new RegExp('/.*/').test(tag.slug)
        if (!hasSubChannel && competitionId) {
            return `/daten${tag.slug}/spielplan/${competitionId}`
        }
        return `/daten${tag.slug}/spielplan`
    }
    return ''
}

export const createHighlightHref = ({ tag }: CreateHighlightHrefProps): string | undefined => {
    if (tag?.categoryType === 'TEAM') {
        return createTeamWidgetHref({ id: tag.contextId, name: tag.title }, 'highlights')
    } else if (tag?.slug) {
        return `/daten${tag.slug}/highlights`
    }
}

export const createCalendarHref = ({ tag, sportId }: CreateCalendarHrefProps): string => {
    if (tag?.slug) {
        const hasSubChannel = new RegExp('/.*/').test(tag.slug)
        if (!hasSubChannel && sportId) {
            return `/daten${tag.slug}/kalender/${sportId}`
        }
        return `/daten${tag.slug}/kalender`
    }
    return ''
}

export const createTourDeFranceResultsHref = ({
    competitionName,
    sportGroupName,
}: CreateTourDeFranceResultsHref): string => {
    if (competitionName && sportGroupName) {
        const urlCompetiton = competitionName.toLowerCase().replaceAll(' ', '-')
        const urlName = sportGroupName.toLowerCase()
        return `/daten/${urlName}/${urlCompetiton}/ergebnisse`
    }
    return ''
}

export const createCleanSheetsHref = ({ tag }: CreateCleansheetsHrefProps): string => {
    if (tag?.slug) {
        return `/daten${tag.slug}/die-weisse-weste`
    }
    return ''
}

export const createNewsHref = ({ currentPath }: CreateNewsHrefProps): string => {
    if (currentPath) {
        const parts = currentPath.split('?')
        const path = parts.shift()
        if (path !== '/') {
            return `${path}/news${parts.length ? `?${parts.join('?')}` : ''}`
        }
    }
    return '/news'
}

export const createRedirectHref = ({
    widget,
    competition,
    match,
    sport,
}: RedirectHrefProps): string => {
    const sportID = sport?.id || match?.sport?.id
    const competitionId = competition?.id || match?.competition?.id
    const sportIdentifier =
        competition?.sportGroup?.sportIdentifier ||
        match?.competition?.sportGroup?.sportIdentifier ||
        match?.sport?.sportGroup?.sportIdentifier
    const matchId = match?.id
    if (sportIdentifier && competitionId) {
        return `/api/redirect/${sportIdentifier}/${sportID}/${competitionId}/${widget}/${matchId}`
    }
    return ''
}

export const createPlaylistHref = (tag?: TagProps | LabelProps): string | undefined => {
    return tag?.slug ? `/tv-video/playlisten${tag?.slug}` : undefined
}

export { createTeamHref, createTeamWidgetHref }
