import React, {
    createContext,
    useContext,
    FunctionComponent,
    useCallback,
    PropsWithChildren,
} from 'react'
import { useRouter } from 'next/router'
import { NavigationData, NavigationItem } from '@/utils/navigation/Standard/types/NavigationItem'

type NavigationContextType = {
    navigation?: NavigationData
    isActive: (item: NavigationItem) => boolean
}

const NavigationContext = createContext<NavigationContextType>({
    isActive: () => false,
})

export const useNavigation = (): NavigationContextType => useContext(NavigationContext)

export const NavigationProvider: FunctionComponent<
    PropsWithChildren<{ navigation?: NavigationData }>
> = ({ children, navigation }) => {
    const router = useRouter()

    const isActive = useCallback(
        (item: NavigationItem): boolean => {
            return (
                router.pathname === item.href ||
                (router.asPath.includes('tv-video') && item.href === '/tv-video')
            )
        },
        [router]
    )

    return (
        <NavigationContext.Provider
            value={{
                navigation,
                isActive,
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}
