import FloatingText from '@sport1/news-styleguide/FloatingText'
import React, { FC, useCallback, useMemo } from 'react'
import { PropsType } from '@sport1/news-styleguide/Types'
import { slugify } from '@/helpers/slugHelper'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useIsOnTeamPage } from '@/helpers/teamHelper'
import { sport1Bold } from '@/helpers/fonts'

type BreadCrumb = {
    title: string
    link?: string
    attachment?: string
}
type props = {
    breadCrumbs: BreadCrumb[]
}

type TextStyle = Pick<PropsType, 'paddingX' | 'fontSize' | 'fontFamily'> & {
    color?: 'onyx' | 'keshi-3'
}

type TextObject = {
    content: string
    href?: string
    onPress?: () => void
    webHeadlineType?: 'h1'
    textStyle: TextStyle
}

const BreadCrumbsData: FC<props> = ({ breadCrumbs }) => {
    const { trackInteraction } = useTracking()
    const trackOnPress = useCallback(
        (clickedBreadCrumb: BreadCrumb) => {
            trackInteraction({
                devices: 'all',
                interaction_category: 'navigation',
                interaction_action: 'click',
                interaction_label: `breadcrumbs_${breadCrumbs
                    .map(item => slugify(item.title))
                    .join('_')}_${slugify(clickedBreadCrumb.title)}`,
            })
        },
        [breadCrumbs, trackInteraction]
    )
    const isOnTeamPage = useIsOnTeamPage()

    const textObjects = useMemo(() => {
        const textElements: TextObject[] = []
        breadCrumbs.forEach((item, index) => {
            const isLast = index === breadCrumbs.length - 1
            textElements.push({
                content: item.title,
                href: !isLast ? item.link : undefined,
                onPress: !isLast ? () => trackOnPress(item) : undefined,
                textStyle: {
                    color: isLast ? 'onyx' : 'keshi-3',
                    fontSize: '12px',
                    fontFamily: isLast && isOnTeamPage ? sport1Bold.style.fontFamily : undefined,
                },
                webHeadlineType: isLast ? 'h1' : undefined,
            })

            if (item.attachment) {
                textElements.push({
                    content: item.attachment,
                    textStyle: {
                        color: isLast ? 'onyx' : 'keshi-3',
                        fontSize: '12px',
                    },
                })
            }

            if (!isLast) {
                textElements.push({
                    content: '>',
                    textStyle: {
                        color: 'keshi-3',
                        fontSize: '12px',
                        paddingX: 2,
                    },
                })
            }
        })
        return textElements
    }, [breadCrumbs, isOnTeamPage, trackOnPress])

    return <FloatingText lineHeight="16px" textAlign="left" textObjects={textObjects} />
}

export default BreadCrumbsData
