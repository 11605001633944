import { RefObject, useCallback, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const useOuterClick = (ref: RefObject<HTMLElement>, callback: Function): void => {
    const handleClick = useCallback(
        (event: Event): void => {
            if (
                ref &&
                ref.current &&
                event.target instanceof HTMLElement &&
                !ref.current.contains(event.target)
            ) {
                callback()
            }
        },
        [callback, ref]
    )

    useEffect(() => {
        window.addEventListener('click', handleClick)
        return (): void => {
            window.removeEventListener('click', handleClick)
        }
    }, [handleClick])
}

export default useOuterClick
