import React, { useCallback, useEffect, useMemo, useState, useTransition, memo } from 'react'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Touchable from '@sport1/news-styleguide/Touchable'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import dynamic from 'next/dynamic'
import Font from '@sport1/news-styleguide/Font'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import { slugify } from '@/helpers/slugHelper'
import Search from '@/layouts/Standard/Header/components/HeaderBanner/components/Search'
import { Originals } from '@/layouts/Standard/Header/components/Originals'
import { useNavigation } from '@/utils/navigation/Standard'
import { NavigationItem, typeIconMapping } from '@/utils/navigation/Standard/types/NavigationItem'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import { SizeTypes } from '@/types/size'
import { sport1Bold } from '@/helpers/fonts'
import WrappedTouchable from '@/components/WrappedTouchable'
import { TEMPORERY_EXATLON_OBJECT } from '@/components/TagList/types'

const BurgerMenu = dynamic(() => import('@/components/BurgerMenu'), { ssr: false })
const PianoHeaderButton = dynamic(
    () => import('@/components/ThirdParty/Piano/Composer/components/HeaderButton'),
    { ssr: false }
)

type Props = {
    renderSearch?: boolean
}

const HeaderBanner: React.FC<Props> = ({ renderSearch = true }) => {
    const { currentDevice } = useMediaQueryContext()
    const isMobile = useMemo(() => currentDevice === SizeTypes.MOBILE, [currentDevice])
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)
    const [renderBurgerMenu, setRenderBurgerMenu] = useState(false)
    const [renderBurgerMenuItem, setRenderBurgerMenuItem] = useState(false)
    const { navigation, isActive } = useNavigation()
    const { trackInteraction } = useTracking()
    const [, startTransition] = useTransition()

    useEffect(() => setRenderBurgerMenuItem(true), [])

    const startRenderBurgerMenu = useCallback((): void => {
        if (!renderBurgerMenu) {
            startTransition(() => setRenderBurgerMenu(true))
        }
    }, [renderBurgerMenu])

    const onBurgerMenuIconPress = useCallback((): void => {
        startTransition(() => {
            setIsBurgerMenuOpen(prevState => !prevState)
            startRenderBurgerMenu()
        })
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'menu',
            platforms: isMobile ? 'mobile' : 'desktop',
        })
    }, [isMobile, startRenderBurgerMenu, trackInteraction])

    const onSport1LogoPress = useCallback((): void => {
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'logo',
            platforms: isMobile ? 'mobile' : 'desktop',
        })
    }, [trackInteraction, isMobile])

    const onTopNavigationItemPress = useCallback(
        (navigationItem: NavigationItem): void => {
            trackInteraction({
                devices: 'all',
                interaction_category: 'navigation',
                interaction_action: 'click',
                interaction_label: `navigation_${slugify(navigationItem.caption)}`,
                platforms: 'desktop',
            })
        },
        [trackInteraction]
    )

    const burgerMenuItem = useMemo(() => {
        if (!renderBurgerMenuItem) return null
        return (
            <div onMouseOver={startRenderBurgerMenu}>
                <Touchable testID="burger-menu-icon" onPress={onBurgerMenuIconPress}>
                    <NonFlexingContainer centerHorizontal>
                        <FontelloIcon name="burger-menu" color="onyx" fontSize="21px" />
                    </NonFlexingContainer>
                </Touchable>
            </div>
        )
    }, [renderBurgerMenuItem, startRenderBurgerMenu, onBurgerMenuIconPress])

    return (
        <NonFlexingContainer
            height={[44, 44, 78]}
            backgroundColor="pearl"
            horizontal
            centerVertical
            centerHorizontal
            width="100%"
            maxWidth={1040}
            paddingX={['spacing-none', 'spacing-none', 'spacing-6', 'spacing-9']}
            marginX={['spacing-none', 'spacing-none', 'spacing-none', 'auto']}
        >
            <NonFlexingContainer
                position={['absolute', 'absolute', 'relative', 'relative']}
                right={[0, 0, 0, 0]}
                centerHorizontal
                centerVertical
                width={[44, 44, '22px', '22px']}
                height={[44, 44, '22px', '22px']}
                marginRight={['spacing-none', 'spacing-none', 'spacing-9', 'spacing-10']}
                marginLeft={['spacing-none', 'spacing-none', 'spacing-6', 'spacing-none']}
            >
                {renderBurgerMenu && (
                    <BurgerMenu
                        isBurgerMenuOpen={isBurgerMenuOpen}
                        onStateChange={setIsBurgerMenuOpen}
                    />
                )}
                {burgerMenuItem}
            </NonFlexingContainer>

            <NonFlexingContainer
                position={['absolute', 'absolute', 'relative']}
                left={[16, 16, 0]}
                minWidth={isMobile ? 71 : 91}
                marginRight={['spacing-none', 'spacing-none', 'spacing-9']}
            >
                <Sport1Logo
                    href="/"
                    onPress={onSport1LogoPress}
                    type="OnLight"
                    width={isMobile ? 71 : 91}
                    testID="header-banner-sport1-logo"
                />
            </NonFlexingContainer>

            {renderSearch && (
                <FlexingContainer
                    right={[44, 44, 0, 0]}
                    width={[44, 44, 44, '50%']}
                    height={[44, 44, 44, '50%']}
                    centerVertical={currentDevice !== SizeTypes.DESKTOP}
                    centerHorizontal={currentDevice !== SizeTypes.DESKTOP}
                    position={['absolute', 'absolute', 'absolute', 'relative']}
                    marginRight={['spacing-none', 'spacing-none', 'spacing-6', 'spacing-7']}
                    maxWidth={270}
                >
                    <Search
                        placeholder="Suche"
                        searchUrl={navigation?.search?.queryUrl || ''}
                        popularUrl={navigation?.search?.popularUrl || ''}
                    />
                </FlexingContainer>
            )}

            <FlexingContainer
                display={['none', 'none', 'flex']}
                alignRight={currentDevice === SizeTypes.DESKTOP}
                centerHorizontal={currentDevice !== SizeTypes.DESKTOP}
            >
                <FlexingContainer
                    display="flex"
                    horizontal
                    centerVertical
                    width="100%"
                    className="justify-evenly"
                >
                    {navigation?.topNavigation?.slice(1, 5).map(item => {
                        const isActiveItem = isActive(item)
                        const href = item.href || ''
                        return (
                            <NonFlexingContainer
                                key={item.caption}
                                marginRight={['spacing-10', 'spacing-10', 'spacing-9', 'spacing-9']}
                            >
                                <WrappedTouchable
                                    href={href}
                                    onPress={() => onTopNavigationItemPress(item)}
                                    testID="top-navigation-item"
                                >
                                    <NonFlexingContainer
                                        display={['none', 'none', 'flex', 'flex']}
                                        centerHorizontal
                                        centerVertical
                                        minWidth={0}
                                    >
                                        <FontelloIcon
                                            name={`${typeIconMapping.get(item.iconType)}${
                                                isActiveItem ? '-active' : ''
                                            }`}
                                            color={
                                                isActiveItem ? colorPattern.sky : colorPattern.onyx
                                            }
                                            fontSize="FS20"
                                        />
                                        <Font
                                            fontFamilyVariant="Sport1-Bold"
                                            nextFontFamilyVariant={sport1Bold.style.fontFamily}
                                            fontSize="11px"
                                            lineHeight="lineHeight-16"
                                            color={
                                                isActiveItem ? colorPattern.sky : colorPattern.onyx
                                            }
                                        >
                                            {item.caption}
                                        </Font>
                                    </NonFlexingContainer>
                                </WrappedTouchable>
                            </NonFlexingContainer>
                        )
                    })}
                    {navigation?.originalsItems && (
                        <NonFlexingContainer
                            centerHorizontal
                            centerVertical
                            minWidth={0}
                            horizontal
                            display={['none', 'none', 'none', 'flex']}
                            marginRight="spacing-2"
                        >
                            <Originals
                                tags={
                                    !navigation.originalsItems.find(
                                        el => el.title.toLowerCase() === 'exatlon'
                                    )
                                        ? [TEMPORERY_EXATLON_OBJECT, ...navigation.originalsItems]
                                        : navigation.originalsItems
                                }
                            />
                        </NonFlexingContainer>
                    )}
                </FlexingContainer>
            </FlexingContainer>
            <PianoHeaderButton isMobile={isMobile} />
        </NonFlexingContainer>
    )
}

export default memo(HeaderBanner)
