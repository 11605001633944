interface Category {
    type: string
}

interface Vendor {
    vendorId: string
    categories: Category[]
}

type VendorPurposeMap = Vendor[]

const disclosablePurposes = ['5f23f3b9b6a03825da47b363']
const filterForImportant = (vendor: Vendor) =>
    vendor.categories.some((category: Category) => category.type === 'DISCLOSURE_ONLY')

const timeoutMS = 10000

/**
 * consent to all vendors with a disclosure only purpose
 * but reject all other purposes and vendors
 */
export default function postDisclosureOnlyConsent(callback: (vendorIds: string[]) => void) {
    let calledBack = false

    const timeoutRef = setTimeout(() => {
        calledBack = true
        callback([])
    }, timeoutMS)

    window.__tcfapi('postRejectAll', 2, () => {
        window.__tcfapi(
            'getVendorPurposeMapping',
            2,
            (completeVendorPurposeMap: VendorPurposeMap, success) => {
                if (success) {
                    const importantVendorPurposeMap =
                        completeVendorPurposeMap.filter(filterForImportant)
                    const importantVendorIds = importantVendorPurposeMap.map(
                        vendor => vendor.vendorId
                    )

                    window.__tcfapi(
                        'postCustomConsent',
                        2,
                        vendorIds => {
                            clearTimeout(timeoutRef)
                            if (!calledBack) {
                                callback(vendorIds)
                            }
                        },
                        importantVendorIds,
                        disclosablePurposes,
                        []
                    )
                }
            }
        )
    })
}
