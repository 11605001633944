import { BetsServiceContextProvider, Platform } from '@sport1/bets-service-client'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LabelProps, TagProps } from '@sport1/types/web'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren } from 'react'
import Button from '@sport1/news-styleguide/Button'
import Grid from '@sport1/react-elements/Grid'
import { spacings } from '@sport1/news-styleguide/spacings'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import { useMobile } from '@/hooks/useMobile'
import BottomNavigation from '@/layouts/Standard/BottomNavigation'
import Header from '@/layouts/Standard/Header/Header'
import PageContentLayoutFallback from '@/layouts/Standard/PageContentLayout/Fallback'
import Config from '@/utils/Config'
import { NavigationProvider } from '@/utils/navigation/Standard'
import { NavigationData } from '@/utils/navigation/Standard/types/NavigationItem'
import DisplaySkyAd from '@/components/Ads/AdSlot/DisplaySkyAd'
const InactiveHomePopup = dynamic(() => import('@/components/InactiveHomePopup'), { ssr: false })

type StandardLayoutProps = {
    navigation?: NavigationData
    tagNavigation?: TagProps | LabelProps
    competitions?: (TagProps | LabelProps)[]
    isAdsActive?: boolean // to disable ads, need to pass 'false', otherwise ads enabled
    disableBillboard?: boolean // next to all ads are active we sometimes need to disable only the billboard
    noBack2Home?: boolean // provided by 404 page to not display back 2 home footer
}

const Footer = dynamic(() => import('@/layouts/Standard/Footer/Footer'))

const StandardLayout: FC<StandardLayoutProps & PropsWithChildren> = ({
    navigation,
    competitions,
    children,
    isAdsActive = true,
    disableBillboard = false,
    noBack2Home = false,
}) => {
    const { skyScraper } = useAdPlacementContext()
    const { isMobile } = useMobile()
    const router = useRouter()
    const isHomePage = router.pathname === '/'
    const showBack2Home = !isHomePage && !noBack2Home
    return (
        <div className="relative w-full">
            <BetsServiceContextProvider
                env={Config.BETS_SERVICE_ENV}
                platform={isMobile ? Platform.MOBILE : Platform.DESKTOP}
            >
                <NavigationProvider navigation={navigation}>
                    <InactiveHomePopup />
                    <NonFlexingContainer width="100%">
                        <NonFlexingContainer className="s1-centered">
                            <Header
                                competitions={competitions}
                                disableBillboard={disableBillboard}
                            />
                            {isAdsActive && skyScraper?.placementId && (
                                <>
                                    <DisplaySkyAd
                                        {...skyScraper}
                                        visibleBreadCrumbs={!!navigation?.tag}
                                    />
                                    <DisplaySkyAd
                                        placement="sky"
                                        placementId="sky2"
                                        isLeftSky
                                        visibleBreadCrumbs={!!navigation?.tag}
                                    />
                                </>
                            )}
                            <NonFlexingContainer
                                className="s1-main-content"
                                id="s1-main-content"
                                position="relative"
                                zIndex={10}
                                maxWidth={1040}
                                width="100%"
                                marginX="auto"
                                backgroundColor="#F1F0F5"
                            >
                                {children || <PageContentLayoutFallback />}
                                <BottomNavigation />
                                {showBack2Home && (
                                    <Grid centerHorizontal marginY={spacings['spacing-9']}>
                                        <Button
                                            buttonType="largeButton"
                                            buttonVariant="tertiary"
                                            text="Zur Startseite"
                                            href="/?b2h=true"
                                            paddingY="spacing-5"
                                            paddingX="spacing-9"
                                        />
                                    </Grid>
                                )}
                            </NonFlexingContainer>
                            <Footer />
                        </NonFlexingContainer>
                    </NonFlexingContainer>
                </NavigationProvider>
            </BetsServiceContextProvider>
        </div>
    )
}

export default StandardLayout
