export type SportIdentifierType =
    | 'soccer'
    | 'cycling'
    | 'wintersport'
    | 'basketball'
    | 'handball'
    | 'football'
    | 'icehockey'
    | 'baseball'
    | 'darts'
    | 'tennis'
    | 'rugby'
    | 'us_sport'
    | 'motorsport'
    | 'american_football'
    | 'field_hockey'
    | 'indoor_volleyball'

type SportIdentifierValueProps = {
    id?: string
    name: string
}

export const sportIdentifiers: Record<SportIdentifierType, SportIdentifierValueProps> = {
    soccer: {
        id: 'sport1:sportgroup:1',
        name: 'soccer',
    },
    cycling: {
        name: 'cycling',
    },
    wintersport: {
        name: 'wintersport',
    },
    basketball: {
        name: 'basketball',
    },
    handball: {
        name: 'handball',
    },
    football: {
        name: 'football',
    },
    icehockey: {
        name: 'icehockey',
    },
    baseball: {
        name: 'baseball',
    },
    darts: {
        name: 'darts',
    },
    tennis: {
        name: 'tennis',
    },
    rugby: {
        name: 'rugby',
    },
    us_sport: {
        name: 'us_sport',
    },
    motorsport: {
        name: 'motorsport',
    },
    american_football: {
        name: 'american_football',
    },
    field_hockey: {
        name: 'field_hockey',
    },
    indoor_volleyball: {
        name: 'indoor_volleyball',
    },
}

export default sportIdentifiers
