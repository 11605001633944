/**
 * getJson: Stupid name, but it shall imply that it only can be used for
 * GET requests, expects `content-type: application/json` and unwraps
 * the response to JSON.
 *
 * Why? That's our use case 99% of the time.
 *
 * The function just receices a URL. No passing headers. If you need
 * more, you need to do it yourself with plain old fetch.
 *
 * Subsequent calls do not duplicate requests, but still resolve as if.
 */

import S1Error from '@/utils/errors/S1Error'
import { debug, LogType } from '@/utils/Logger'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Json = any

type Queue = {
    reject: Function
    resolve: Function
}[]

const queues: { [key: string]: Queue | undefined } = {}

function getResponse(url: string, timeout?: number) {
    const Signal = AbortSignal as unknown as AbortSignal & { timeout?: Function }
    const config = timeout && Signal.timeout ? { signal: Signal.timeout(timeout) } : undefined

    return fetch(url, config).then(response => {
        if (!response.ok || response.status >= 400) {
            return Promise.reject(new S1Error(response.status, `Could not fetch ${url}`))
        }
        return response
    })
}

function rejectAll(queue: Queue, error: Error) {
    while (queue.length) {
        queue.shift()?.reject(error)
    }
}

function resolveAll(queue: Queue, json: Json) {
    while (queue.length) {
        queue.shift()?.resolve(json)
    }
}

export default function getJson(url?: string, timeout?: number): Promise<Json> {
    debug(LogType.network, url)

    if (!url) {
        return Promise.reject(new Error('No url given'))
    }

    const queue = (queues[url] = queues[url] || [])

    return new Promise(function getJsonPromise(resolve, reject) {
        queue.push({ resolve, reject })
        const firstRequestOfUrl = queue.length === 1

        if (firstRequestOfUrl) {
            getResponse(url, timeout)
                .then(response => response.json().then(json => resolveAll(queue, json)))
                .catch((error: Error) => rejectAll(queue, error))
        }
    })
}
